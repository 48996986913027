import React from "react";
import OurServices from "./Components/OurServices";
import PastClients from "./Components/PastClients";
import ContactUs from "./Components/ContactUs";
import Footer from "./Footer";
import HeroSection from "./Components/HeroSection";
import AboutUs from "./Components/AboutUs";
import WhyUs from "./Components/WhyChooseUs";

function App() {
  return ( 
    <>
    <div className="h-screen">
      <HeroSection/>
      <AboutUs/>
      <OurServices/>
      <WhyUs/>
      <PastClients/>
      <ContactUs/>
      <Footer/>
    </div>
    </>
   );
}

export default App;