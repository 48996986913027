import React from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

function AboutUs() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
            <div id="about-us" className="container mx-auto mt-4" style={{color:"#001F3F"}}>
                <div data-aos="fade-down" className="sm:text-[35px] font-semibold mx-[40px] sm:mx-[125px] sm:pb-[15px] max-sm:mx-[20px] max-sm:pb-[5px] max-sm:text-[20px]">About Us</div>
                    <hr className=" max-sm:mx-[20px] sm:mx-[125px]"/>

                <div data-aos="fade-right" data-aos-anchor-placement="top-center" className="mx-[40px] max-sm:mx-[20px] sm:mx-[125px] max-md:text-[14px] lg:text-[20px] max-lg:[text-16px] sm:py-[3rem]" >Prakash industrial engineering has evolved from an Industrial Electrical contractor to a comprehensive solution provider with 20 years of experience. Formerly known as Shivani Electricals, we excel in industrial electrical, instrumentation and fabrication.Our commitment to quality and client satisfaction sets us apart, building lasting relationships based on trust. As we celebrate two decades, Prakash industrial engineering remains dedicated to delivering tailored solutions in the evolving landscape of industry. Join us in shaping the future, where experience meets innovation. Experience the power of partnership with Prakash Industrial Engineering.
                <div>
                    <div className="flex font-medium my-6">
                        <a href="#why-us">Know more</a>
                        <a href="#why-us" className="grid content-center ml-[5px] mt-[2px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="20" viewBox="0 0 9 20" fill="none">
                                <path d="M2 2L7 10L2 18" stroke="#FF900D" stroke-width="2.2" stroke-linecap="round"/>
                            </svg>
                        </a>
                    </div>
                </div>
                </div>
            </div>
        </>
     );
}

export default AboutUs;