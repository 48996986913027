import React from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

function HeroSection() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
        <div className="herosection h-[600px] max-md:h-[400px] max-sm:h-[260px] text-center grid place-content-center" style={{color:"#001F3F"}}>
            <div  data-aos="zoom-in-up" className="hero-h1">PRAKASH INDUSTRIAL ENGINEERING</div>
            <div  data-aos="zoom-in-up" className="hero-p">INNOVATIVE SOLUTIONS FOR INDUSTRIAL EXCELLENCE</div>
            <div  data-aos="zoom-in-up" className="flex justify-center mt-[15px]">
                <a href="#about-us" className="hero-abt-btn grid place-items-center">About us</a>
            </div>
        </div>
        </>
     );
}

export default HeroSection;