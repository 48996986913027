import React from "react";
import Gmap from "./Gmaps";
import Emailjs from "./Email";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";


function ContactUs() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
        <div id="contact-us" className="mt-4 container mx-auto xl:h-screen max-sm:h-[105vh] max-xl:h-[165vh]">
            <div data-aos="fade-down" className="sm:text-[35px] font-semibold mx-[40px] sm:mx-[125px] sm:pb-[15px] max-sm:pb-[5px] max-sm:text-[20px]">Contact Us</div>
            <hr className="max-sm:mx-[40px] sm:mx-[125px]"/>

        <div className="xl:flex max-xl:grid justify-center w-100 mx-[125px]">
                <div data-aos="zoom-out" className="justify-center xl:w-3/5">
                    <Emailjs/>
                </div>
                <div data-aos="fade-up" data-aos-duration="3000" className="justify-center max-xl:grid xl:w-2/5">
                    <Gmap/>                    
                </div>
        </div>
        </div>
        </>
     );
}

export default ContactUs;