import React from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

function WhyUs() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
            <div id="why-us" className="container mx-auto mt-4" style={{color:"#001F3F"}}>
                <div data-aos="fade-down" className="sm:text-[35px] font-semibold mx-[40px] sm:mx-[125px] sm:pb-[15px] max-sm:mx-[20px] max-sm:pb-[5px] max-sm:text-[20px]">Why Choose Us</div>
                    <hr className=" max-sm:mx-[20px] sm:mx-[125px]"/>

                <div data-aos="fade-right" data-aos-anchor-placement="top-center" className="mx-[40px] max-sm:mx-[20px] sm:mx-[125px] max-md:text-[14px] lg:text-[20px] max-lg:[text-16px]" >
                <div>
                    <div className="flex font-medium my-4">
                        <span className="grid content-center ml-[5px] mt-[2px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="20" viewBox="0 0 9 20" fill="none">
                                <path d="M2 2L7 10L2 18" stroke="#FF900D" stroke-width="2.2" stroke-linecap="round"/>
                            </svg>
                        </span>
                        <span>‎  High-Quality Work:</span>
                    </div>
                </div>
                At Prakash Industrial Engineering, we take pride in our unwavering commitment to delivering high-quality work. With a legacy of 20 years, we have evolved from an Industrial Electrical contractor to a comprehensive solution provider. Our dedication to excellence ensures that every project, from fabrication to annual maintenance, meets the highest standards, building a reputation founded on precision and reliability.
                </div>
            </div>

            <div className="container mx-auto" style={{color:"#001F3F"}}>
                <div data-aos="fade-right" data-aos-anchor-placement="top-center" className="mx-[40px] max-sm:mx-[20px] sm:mx-[125px] max-md:text-[14px] lg:text-[20px] max-lg:[text-16px]" >
                <div>
                    <div className="flex font-medium my-4">
                        <span className="grid content-center ml-[5px] mt-[2px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="20" viewBox="0 0 9 20" fill="none">
                                <path d="M2 2L7 10L2 18" stroke="#FF900D" stroke-width="2.2" stroke-linecap="round"/>
                            </svg>
                        </span>
                        <span>‎  Expert Team:</span>
                    </div>
                </div>
                Our strength lies in our team of experienced engineers, skilled foremen, and diligent laborers. With a wealth of expertise accumulated over two decades, our team is equipped to tackle diverse challenges in industrial electricals, instrumentation, fabrication, and maintenance. Choose us for a collective force that brings innovation, skill, and reliability to every project.
                </div>
            </div>
        </>
     );
}

export default WhyUs;