import React from "react";

function Gmap() {
    return ( 
        <>
        <div className="flex pb-2 pt-[15px] max-sm:hidden">
                <div className="w-[40px] h-[40px]">
                    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                        <path d="M20 19.1667C18.8949 19.1667 17.8351 18.7277 17.0537 17.9463C16.2723 17.1649 15.8333 16.1051 15.8333 15C15.8333 13.895 16.2723 12.8352 17.0537 12.0538C17.8351 11.2724 18.8949 10.8334 20 10.8334C21.1051 10.8334 22.1649 11.2724 22.9463 12.0538C23.7277 12.8352 24.1667 13.895 24.1667 15C24.1667 15.5472 24.0589 16.089 23.8495 16.5946C23.6401 17.1001 23.3332 17.5594 22.9463 17.9463C22.5594 18.3332 22.1 18.6401 21.5945 18.8495C21.089 19.0589 20.5472 19.1667 20 19.1667ZM20 3.33337C16.9058 3.33337 13.9384 4.56254 11.7504 6.75046C9.56251 8.93839 8.33334 11.9058 8.33334 15C8.33334 23.75 20 36.6667 20 36.6667C20 36.6667 31.6667 23.75 31.6667 15C31.6667 11.9058 30.4375 8.93839 28.2496 6.75046C26.0617 4.56254 23.0942 3.33337 20 3.33337Z" fill="#FF900D"/>
                    </svg>
                </div>
                <p className="text-2xl align-middle flex">Vapi, Gujarat</p>
        </div>
        <div style={{filter:"grayscale(100%)", textDecoration:"none", overflow:"hidden", maxWidth:"100%",width:"500px",height:"466px"}} className="max-sm:!w-[320px] max-sm:!h-[262px]">
            <div id="my-map-display" style={{height:"100%", width:"100%", maxWidth:"100%"}}><iframe style={{height:"100%",width:"100%",border:"0px"}} src="https://www.google.com/maps/embed/v1/place?q=Vapi,+Gujarat,+India&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="embed-ded-maphtml" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="inject-map-data">premium bootstrap themes</a>
        </div>
        </>

     );
}

export default Gmap;