import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import React from "react";
// import "../../src/contact.scss";

const Emailjs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast("Message sent!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: "submit-feedback success message-sent-toast",
      toastId: "notifyToast",
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, company, phone, message } = data;
    // if not valid 
    // return
    try {
      const templateParams = {
        name,
        company,
        phone,
        message,
      };

      await emailjs.send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_USER_ID
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="grid">
      <div className="container-contact-us">
        <div className="d-flex align-items-center" style={{maxWidth:"100%"}}>
          <div className="md:grid md:pt-[50px] max-md:pt-[25px]">
            <div className="md:w-[560px] max-md:grid max-md:place-content-center">
              <div className="container d-flex justify-content-center contact-form-input-fields">
                <div className="ContactForm">
                  <div className="container">
                    <div className="grid">
                      <div className="col-12 text-center">
                        <div className="contactForm-tab">
                          <form
                            id="contact-form"
                            onSubmit={handleSubmit(onSubmit)}
                            noValidate
                          >
                            {/* Row 1 of form */}
                            <div className="grid /*sm:grid-cols-2*/ formRow">
                              <div className="grid margin-input sm:px-0">
                                <input
                                  style={{paddingLeft:"20px"}}
                                  type="text"
                                  name="name"
                                  {...register("name", {
                                    required: {
                                      value: true,
                                      message: "Please enter your Name*",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Please use 30 characters or less",
                                    },
                                  })}
                                  className="border-2 form-control formInput input-contact-us max-sm:mb-[20px] sm:mb-[40px]"
                                  placeholder="Name"
                                ></input>
                                {errors.name && (
                                  <span className="errorMessage">
                                    {errors.name.message}
                                  </span>
                                )}
                              </div>
                              <div className="grid margin-input sm:px-0">
                                <input
                                  style={{paddingLeft:"20px"}}
                                  type="number"
                                  name="phone"
                                  {...register("phone", {
                                    required: {
                                      value: true,
                                      message: "Please enter a valid Contact Number*",
                                    },
                                    maxLength: {
                                      value: 75,
                                      message:
                                        "phone cannot exceed 20 characters",
                                    },
                                  })}
                                  className="border-2 form-control formInput input-contact-us max-sm:mb-[20px] sm:mb-[40px]"
                                  placeholder="Phone Number"
                                ></input>
                                {errors.phone && (
                                  <span className="errorMessage">
                                    {errors.phone.message}
                                  </span>
                                )}
                              </div>
                            </div>
                            {/* Row 2 of form */}
                            <div className="grid margin-input sm:px-0">
                                <input
                                  style={{paddingLeft:"20px"}}
                                  type="text"
                                  name="company"
                                  {...register("company", {
                                    required: {
                                      value: true,
                                      message: "Please enter your Company Name*",
                                    },
                                    maxLength: {
                                      value: 50,
                                      message:
                                        "Please use 50 characters or less",
                                    },
                                  })}
                                  className="border-2 form-control formInput input-contact-us max-sm:mb-[20px] sm:mb-[40px]"
                                  placeholder="Comapny Name"
                                ></input>
                                {errors.name && (
                                  <span className="errorMessage">
                                    {errors.name.message}
                                  </span>
                                )}
                              </div>
                            {/* Row 3 of form */}
                            <div className="grid grid-cols-1 formRow">
                              <div className="grid margin-input">
                                <textarea
                                  style={{paddingLeft:"20px", backgroundColor:"#EFEFEF", fontSize:"20px", paddingTop:"5px"}}
                                  rows={4}
                                  name="message"
                                  {...register("message", {
                                    required: true,
                                  })}
                                  className="border-2 form-control formInput max-sm:mb-[20px] sm:mb-[40px]"
                                  placeholder="Message"
                                ></textarea>
                                {errors.message && (
                                  <span className="errorMessage">
                                    Please enter a message*
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="grid place-content-center">
                              <button
                                className="submit-btn"
                                type="submit"
                              >
                                SUBMIT
                              </button>
                            </div>

                          </form>
                            <div style={{ marginTop: "40px" }} className="d-flex">
                              {/* <div className="contact-us-address">
                                Delta Fitness Training Centre, Block -S Sector
                                -75, BPTP Greater, Near Discovery Society
                                Greater Faridabad.
                              </div> */}
                            </div>

                            
                        </div>
                        
                        
                      </div>
                        <ToastContainer />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>      
        </div>
      </div>
    </div>
  );
};

export default Emailjs;
