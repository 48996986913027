
import React from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";

function OurServices() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
        <>
        <div id="our-services" className="mt-4 container mx-auto" style={{color:"#001F3F"}}>
            <div data-aos="fade-down" className="sm:text-[35px] font-semibold mx-[40px] sm:mx-[125px] sm:pb-[15px] max-sm:pb-[5px] max-sm:text-[20px]">Our Services</div>
        
        <hr className="mx-[40px] sm:mx-[125px]"/>

        <div className="my-12 grid 2xl:grid-cols-2 place-items-center max-sm:hidden">
            <div data-aos="fade-left" className="flex card-props mb-[50px] 2xl:mr-[-125px]">
                <div className="w-1/3 flex justify-center">
                    <img src="https://ik.imagekit.io/prakash/icons/Electrical%20Services%20icon.svg" alt="cardimg" className="w-[120px]"/>
                </div>
                <div className="w-2/3 grid place-content-center pr-6">
                    <p className="h1-card-texts">Industrial Electrical Services.</p>
                    <p className="p-card-texts">Providing expert solutions in industrial electricals, we ensure seamless installation, maintenance, and upgrades. Our experienced team guarantees efficient electrical systems tailored to your industrial needs.</p>
                </div>
            </div>

            <div data-aos="fade-right" className="flex card-props mb-[50px] 2xl:ml-[-125px]">
                <div className="w-1/3 flex justify-center">
                    <img src="https://ik.imagekit.io/prakash/icons/%20Instrumentation%20Solutions%20icon.svg" alt="cardimg" className="w-[120px]"/>
                </div>
                <div className="w-2/3 grid place-content-center pr-6">
                    <p className="h1-card-texts">Instrumentation Solutions.</p>
                    <p className="p-card-texts">From precise installations to intricate instrumentation, we deliver comprehensive services to optimize your industrial processes. Trust us for reliable solutions that enhance efficiency and precision.</p>
                </div>
            </div>

            <div data-aos="fade-left" className="flex card-props max-2xl:mb-[50px] 2xl:mr-[-125px]">
                <div className="w-1/3 flex justify-center">
                    <img src="https://ik.imagekit.io/prakash/icons/Maintenance%20icon.svg" alt="cardimg" className="w-[120px]"/>
                </div>
                <div className="w-2/3 grid place-content-center pr-6">
                    <p className="h1-card-texts">Maintenance Services.</p>
                    <p className="p-card-texts">With a focus on preventive and responsive maintenance, our team ensures the uninterrupted operation of your industrial systems. Rely on us for meticulous maintenance services that minimize downtime and maximize productivity.</p>
                </div>
            </div>

            <div data-aos="fade-right" className="flex card-props 2xl:ml-[-125px]">
                <div className="w-1/3 flex justify-center">
                    <img src="https://ik.imagekit.io/prakash/icons/fabrication%20icon.svg" alt="cardimg" className="w-[120px]"/>
                </div>
                <div className="w-2/3 grid place-content-center pr-6">
                    <p className="h1-card-texts">Fabrication Solutions.</p>
                    <p className="p-card-texts">Our fabrication services stand out for precision and reliability. From conceptualization to execution, we offer top-notch fabrication solutions, ensuring the seamless integration of structures into your industrial setup.</p>
                </div>
            </div>

        </div>

        <div className="grid place-items-center sm:hidden">
            <div data-aos="zoom-out" className="card-props-sm mt-[18px]" style={{color:"#001F3F"}}>
                <div className="flex grid grid-cols-12 my-[12px]">
                    <img className="col-span-3 w-[50px] place-self-center" src="https://ik.imagekit.io/prakash/icons/Electrical%20Services%20icon.svg" alt="mob-card-img"/>
                    <span className="col-span-9 grid content-center">Industrial Electrical Services.</span>
                </div>
                <p className="text-xs mx-[12px] my-[12px]">
                Providing expert solutions in industrial electricals, we ensure seamless installation, maintenance, and upgrades. Our experienced team guarantees efficient electrical systems tailored to your industrial needs.
                </p>
            </div>

            <div data-aos="zoom-out" className="card-props-sm mt-[18px]" style={{color:"#001F3F"}}>
                <div className="flex grid grid-cols-12 my-[12px]">
                    <img className="col-span-3 w-[50px] place-self-center" src="https://ik.imagekit.io/prakash/icons/%20Instrumentation%20Solutions%20icon.svg" alt="mob-card-img"/>
                    <span className="col-span-9 grid content-center">Instrumentation Solutions.</span>
                </div>
                <p className="text-xs mx-[12px] my-[12px]">
                From precise installations to intricate instrumentation, we deliver comprehensive services to optimize your industrial processes. Trust us for reliable solutions that enhance efficiency and precision.
                </p>
            </div>

            <div data-aos="zoom-out" className="card-props-sm mt-[18px]" style={{color:"#001F3F"}}>
                <div className="flex grid grid-cols-12 my-[12px]">
                    <img className="col-span-3 w-[50px] place-self-center" src="https://ik.imagekit.io/prakash/icons/Maintenance%20icon.svg" alt="mob-card-img"/>
                    <span className="col-span-9 grid content-center">Maintenance Services.</span>
                </div>
                <p className="text-xs mx-[12px] my-[12px]">
                With a focus on preventive and responsive maintenance, our team ensures the uninterrupted operation of your industrial systems. Rely on us for meticulous maintenance services that minimize downtime and maximize productivity.
                </p>
            </div>

            <div data-aos="zoom-out" className="card-props-sm mt-[18px] mb-[18px]" style={{color:"#001F3F"}}>
                <div className="flex grid grid-cols-12 my-[12px]">
                    <img className="col-span-3 w-[50px] place-self-center" src="https://ik.imagekit.io/prakash/icons/fabrication%20icon.svg" alt="mob-card-img"/>
                    <span className="col-span-9 grid content-center">Fabrication Solutions.</span>
                </div>
                <p className="text-xs mx-[12px] my-[12px]">
                Our fabrication services stand out for precision and reliability. From conceptualization to execution, we offer top-notch fabrication solutions, ensuring the seamless integration of structures into your industrial setup.
                </p>
            </div>
        </div>
        </div>
        </>
     );
}

export default OurServices;
