import React from "react"

export default function Footer(){
    return (
    <>
            <div className="h-2/7 max-lg:h-[300px] max-md:h-[425px] max-md:pb-[30px] footer-props grid grid-cols-4 max-xl:grid-cols-3 max-md:grid-cols-1 px-[100px] max-md:px-[50px] pb-[25px]">
                <div className="grid place-content-center max-md:mb-[-5rem]">
                    <img  className="w-[250px] max-md:w-[200px] max-w-fit" src="https://ik.imagekit.io/prakash/footer%20logo.png" alt=""/>
                </div>

                
                <div className="col-span-2 max-xl:hidden grid place-content-center footer-addy text-center mx-8">
                    <span>A 302 Ashwini apartment 29-30 Haria Park,</span><span> Dungra,Vapi,396193, District-Valsad, Gujarat</span>
                </div>

                <div className=" max-xl:hidden text-white grid place-content-center">
                    <div className="flex">
                        <div className="grid place-content-center">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M33.25 35C29.7778 35 26.3472 34.2428 22.9583 32.7283C19.5694 31.2139 16.4861 29.0683 13.7083 26.2917C10.9306 23.5139 8.785 20.4306 7.27167 17.0417C5.75833 13.6528 5.00111 10.2222 5 6.75C5 6.25 5.16667 5.83333 5.5 5.5C5.83333 5.16667 6.25 5 6.75 5H13.5C13.8889 5 14.2361 5.13222 14.5417 5.39667C14.8472 5.66111 15.0278 5.97333 15.0833 6.33333L16.1667 12.1667C16.2222 12.6111 16.2083 12.9861 16.125 13.2917C16.0417 13.5972 15.8889 13.8611 15.6667 14.0833L11.625 18.1667C12.1806 19.1944 12.84 20.1872 13.6033 21.145C14.3667 22.1028 15.2072 23.0267 16.125 23.9167C16.9861 24.7778 17.8889 25.5767 18.8333 26.3133C19.7778 27.05 20.7778 27.7233 21.8333 28.3333L25.75 24.4167C26 24.1667 26.3267 23.9789 26.73 23.8533C27.1333 23.7278 27.5289 23.6933 27.9167 23.75L33.6667 24.9167C34.0556 25.0278 34.375 25.2294 34.625 25.5217C34.875 25.8139 35 26.14 35 26.5V33.25C35 33.75 34.8333 34.1667 34.5 34.5C34.1667 34.8333 33.75 35 33.25 35Z" fill="#FF900D"/>
                            </svg>
                        </div>
                        <div className="grid place-content-center">
                        <a href="tel:+91-9904028965">+91-9904028965</a><a href="tel:+91-6352680348"> +91-6352680348</a>
                        </div>
                    </div>
                    
                    <div className="flex">
                        <div className="grid place-content-center w-[40px] h-[40px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
                                <path d="M2.69167 23.3333C1.925 23.3333 1.285 23.0767 0.771667 22.5633C0.257222 22.0489 0 21.4083 0 20.6417V2.69167C0 1.925 0.257222 1.285 0.771667 0.771666C1.285 0.257222 1.925 0 2.69167 0H27.3083C28.075 0 28.715 0.257222 29.2283 0.771666C29.7428 1.285 30 1.925 30 2.69167V20.6417C30 21.4083 29.7433 22.0483 29.23 22.5617C28.7156 23.0761 28.075 23.3333 27.3083 23.3333H2.69167ZM15 11.8583L28.3333 3.14167L27.82 1.66667L15 10L2.18 1.66667L1.66667 3.14167L15 11.8583Z" fill="#FF900D"/>
                            </svg>
                        </div>
                        <div className="grid place-content-center">
                            <a href = "mailto:prakashindustrialengg@gmail.com">prakashindustrialengg@gmail.com</a>
                        </div>
                    </div>
                </div>

                <div className="xl:hidden col-span-2 ml-[15rem] max-lg:ml-[7.5rem] max-lg:mr-[-3.5rem] max-md:mr-[0rem] max-md:ml-[0rem] max- grid place-content-center">
                    <div className="grid place-content-center text-white text-center md:text-base max-md:text-xs max-md:whitespace-nowrap">
                        <span>A 302 Ashwini apartment 29-30 Haria Park,</span><span> Dungra,Vapi,396193, District-Valsad, Gujarat</span>
                    </div>

                    <div className="grid place-content-center text-white ">
                        <div className="flex mt-[15px] max-md:mt-[5px] place-content-center">
                            <div className="max-md:w-[30px] mr-[5px] max-md:grid max-md:place-content-center max-md:justify-center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 40 40" fill="none">
                                    <path d="M33.25 35C29.7778 35 26.3472 34.2428 22.9583 32.7283C19.5694 31.2139 16.4861 29.0683 13.7083 26.2917C10.9306 23.5139 8.785 20.4306 7.27167 17.0417C5.75833 13.6528 5.00111 10.2222 5 6.75C5 6.25 5.16667 5.83333 5.5 5.5C5.83333 5.16667 6.25 5 6.75 5H13.5C13.8889 5 14.2361 5.13222 14.5417 5.39667C14.8472 5.66111 15.0278 5.97333 15.0833 6.33333L16.1667 12.1667C16.2222 12.6111 16.2083 12.9861 16.125 13.2917C16.0417 13.5972 15.8889 13.8611 15.6667 14.0833L11.625 18.1667C12.1806 19.1944 12.84 20.1872 13.6033 21.145C14.3667 22.1028 15.2072 23.0267 16.125 23.9167C16.9861 24.7778 17.8889 25.5767 18.8333 26.3133C19.7778 27.05 20.7778 27.7233 21.8333 28.3333L25.75 24.4167C26 24.1667 26.3267 23.9789 26.73 23.8533C27.1333 23.7278 27.5289 23.6933 27.9167 23.75L33.6667 24.9167C34.0556 25.0278 34.375 25.2294 34.625 25.5217C34.875 25.8139 35 26.14 35 26.5V33.25C35 33.75 34.8333 34.1667 34.5 34.5C34.1667 34.8333 33.75 35 33.25 35Z" fill="#FF900D"/>
                                </svg>
                            </div>
                            <div className="grid place-content-center md:text-base max-md:text-xs">
                                <a href="tel:+91-6352680348">+91-6352680348</a><a href="tel:+91-9904028965"> +91-9904028965</a>
                            </div>
                        </div>
                        
                        <div className="flex mt-[15px] max-md:mt-[5px]">
                            <div className="mr-[5px] w-[35px] max-md:w-[30px] h-[35px] max-md:h-[30px] ">
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24" fill="none">
                                    <path d="M2.69167 23.3333C1.925 23.3333 1.285 23.0767 0.771667 22.5633C0.257222 22.0489 0 21.4083 0 20.6417V2.69167C0 1.925 0.257222 1.285 0.771667 0.771666C1.285 0.257222 1.925 0 2.69167 0H27.3083C28.075 0 28.715 0.257222 29.2283 0.771666C29.7428 1.285 30 1.925 30 2.69167V20.6417C30 21.4083 29.7433 22.0483 29.23 22.5617C28.7156 23.0761 28.075 23.3333 27.3083 23.3333H2.69167ZM15 11.8583L28.3333 3.14167L27.82 1.66667L15 10L2.18 1.66667L1.66667 3.14167L15 11.8583Z" fill="#FF900D"/>
                                </svg>
                            </div>
                            <div className="md:text-base max-md:text-xs">
                                <a href = "mailto:prakashindustrialengg@gmail.com">prakashindustrialengg@gmail.com</a>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

            <div className="relative grid place-items-center bottom-[50px]">
                <svg xmlns="http://www.w3.org/2000/svg" width="133" height="46" viewBox="0 0 133 46" fill="none">
                    <a href="https://www.linkedin.com/company/prakash-industrial-engineering">
                        <path d="M77.4167 35.7778H77.9167V35.2778V25.2667C77.9167 23.5056 77.2378 21.8139 76.0249 20.5642C74.8116 19.3141 73.1626 18.6089 71.44 18.6089C70.1529 18.6089 68.731 19.201 67.6867 20.1709V19.4678V18.9678H67.1867H62.0717H61.5717V19.4678V35.2778V35.7778H62.0717H67.1867H67.6867V35.2778V25.9656C67.6867 24.7683 68.618 23.8211 69.735 23.8211C70.2782 23.8211 70.8023 24.0433 71.1911 24.4439C71.5804 24.845 71.8017 25.3921 71.8017 25.9656V35.2778V35.7778H72.3017H77.4167ZM58.6617 35.7778H59.1617V35.2778V19.4678V18.9678H58.6617H53.5833H53.0833V19.4678V35.2778V35.7778H53.5833H58.6617ZM78.3333 6.5C79.1683 6.5 79.9722 6.8416 80.5673 7.45472C81.1629 8.06836 81.5 8.90372 81.5 9.77778V36.2222C81.5 37.0963 81.1629 37.9316 80.5673 38.5453C79.9722 39.1584 79.1683 39.5 78.3333 39.5H52.6667C51.8317 39.5 51.0278 39.1584 50.4327 38.5453C49.8371 37.9316 49.5 37.0963 49.5 36.2222V9.77778C49.5 8.90372 49.8371 8.06836 50.4327 7.45472C51.0278 6.8416 51.8317 6.5 52.6667 6.5H78.3333ZM56.1133 17.0022C57.0677 17.0022 57.9799 16.6115 58.65 15.921C59.3197 15.2311 59.6933 14.2984 59.6933 13.3289C59.6933 11.3142 58.1122 9.63667 56.1133 9.63667C55.1541 9.63667 54.2373 10.0294 53.5637 10.7234C52.8906 11.4169 52.515 12.3544 52.515 13.3289C52.515 15.3514 54.122 17.0022 56.1133 17.0022Z" fill="#FF900D" stroke="#FF900D"/>
                    </a>

                    <path d="M27.8567 20.895H23.3733V18.1833C23.3733 17.4765 23.4778 17.0212 23.7195 16.7342C23.945 16.4664 24.39 16.2333 25.3683 16.2333H28.0317H28.5317V15.7333V11.2333V10.7837L28.0846 10.7361C26.7769 10.597 25.4626 10.5293 24.1476 10.5333C22.1263 10.5335 20.3726 11.1524 19.1226 12.3884C17.871 13.6261 17.18 15.4275 17.18 17.6833V20.895H13.3333H12.8333V21.395V26.425V26.925H13.3333H17.1783V38.8333H1.84C1.09948 38.8333 0.5 38.2339 0.5 37.4933V7.84C0.5 7.09948 1.09948 6.5 1.84 6.5H31.4933C32.2339 6.5 32.8333 7.09948 32.8333 7.84V37.4933C32.8333 38.2339 32.2339 38.8333 31.4933 38.8333H23.375V26.925H27.2083H27.648L27.7042 26.4889L28.3526 21.4589L28.4252 20.895H27.8567Z" fill="#FF900D" stroke="#FF900D"/>
                    
                    <a href="mailto:prakashindustrialengg@gmail.com">
                        <path d="M129.667 14.4421V32.5834C129.667 34.05 129.179 35.4613 128.305 36.5284C127.43 37.5955 126.233 38.2378 124.96 38.3238L124.667 38.3334H101.333C100.058 38.3335 98.8308 37.7731 97.9029 36.7669C96.975 35.7608 96.4165 34.3848 96.3416 32.9207L96.3333 32.5834V14.4421L112.075 26.5114L112.268 26.6379C112.496 26.7659 112.746 26.8325 113 26.8325C113.254 26.8325 113.504 26.7659 113.732 26.6379L113.925 26.5114L129.667 14.4421Z" fill="#FF900D"/>
                        <path d="M124.667 7.66675C126.467 7.66675 128.045 8.75925 128.925 10.4018L113 22.611L97.075 10.4018C97.4929 9.6213 98.067 8.96702 98.7495 8.49356C99.432 8.02011 100.203 7.74122 100.998 7.68016L101.333 7.66675H124.667Z" fill="#FF900D"/>
                    </a>
                </svg>
            </div>

            <div className="flex justify-center text-black mt-[-45px] max-md:whitespace-nowrap max-md:text-[12px] max-sm:text-[9px]" style={{background:"white"}}>
            <span>All Rights Reserved | ©2023 by Prakash Industrial Engineering.</span>
            </div>
    </>
    )
    
}
