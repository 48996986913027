import React from "react";
import Aos from "aos";
import "aos/dist/aos.css"
import { useEffect } from "react";


  const Murli = 'https://ik.imagekit.io/prakash/clientlogos/murli.jpg?updatedAt=1701794240282';
  const Jodhani = 'https://ik.imagekit.io/prakash/clientlogos/Jodhani-pages-logo-ratina-2.png?updatedAt=1701794240258';
  const Silvertone = 'https://ik.imagekit.io/prakash/clientlogos/silvertone.png?updatedAt=1701794240276';
  const Bindal = 'https://ik.imagekit.io/prakash/clientlogos/bindal.png?updatedAt=1701794240252';
  const Vijay = 'https://ik.imagekit.io/prakash/clientlogos/Vijay%20Anand%20Kraft%20Papers%20Pvt%20Ltd.png?updatedAt=1701794565527';
  const Paswara = 'https://ik.imagekit.io/prakash/clientlogos/paswara.jpeg?updatedAt=1701794240255';
  const sidhbalipapermills = 'https://ik.imagekit.io/prakash/clientlogos/sidhbalipapermills.jpg?updatedAt=1701794240260';

  
  const clients = [
      {
          name: 'Vijay Anand Kraft Paper',
          logo: Vijay,
      },
      {
          name: 'Murli Agro Paper Ltd',
          logo: Murli,
      },
      {
          name: 'Jhodani Paper Mill',
          logo: Jodhani
      },
      {
          name: 'Shree Sidhbali Paper Mills Ltd',
          logo: sidhbalipapermills,
      },
      {
          name: 'Silvertone Pulp And Paper Ltd',
          logo: Silvertone,
      },
      {
        name: 'Paswara Papers Ltd',
        logo: Paswara,
    },
      {
          name: 'Bindal Papers',
          logo: Bindal
      },
      // Add more clients as needed
  ];

function PastClients() {
    useEffect(() =>{
        Aos.init({duration: 2000,
                once: true});
    }, []);
    return ( 
    <>
    <div id="past-clients" className="pt-4 bg-client-cards" style={{color:"#001F3F"}}>
        <div className="container mx-auto">
                <div data-aos="fade-down" className="sm:text-[35px] font-semibold mx-[40px] sm:mx-[125px] sm:pb-[15px] max-sm:pb-[5px] max-sm:text-[20px]">Our Clients</div>
            <hr className="max-sm:mx-[40px] sm:mx-[125px]"/>

            <div className="sm:pb-[100px] max-sm:pb-[50px]">
            <div className='overflow-hidden py-8 max-sm:mx-[40px] sm:mx-[125px]'>
            <div className='flex logo-animate-left sm:gap-32 max-sm:gap-12 w-max'>
                <div className=" flex w-max sm:gap-32 max-sm:gap-12">
                    {clients.map((client, index) => (
                        <div className="">
                            <img src={client.logo} alt={client.name} width={100} height={100} className="flex justify-center items-center w-full h-full object-contain sm:w-[10rem] max-sm:w-[5rem]"/>
                            <span style={{color:"#001F3F"}} className="flex justify-center items-center max-md:text-[14px]">{client.name}</span>
                        </div>                        
                        
                    ))}
                </div>
                <div className=" flex w-max sm:gap-32 max-sm:gap-12">
                    {clients.map((client, index) => (
                        <div>
                            <img src={client.logo} alt={client.name} width={100} height={100} className="flex justify-center items-center w-full h-full object-contain sm:w-[10rem] max-sm:w-[5rem]" />
                            <span style={{color:"#001F3F"}} className="flex justify-center items-center max-md:text-[14px]">{client.name}</span>
                        </div>   
                    ))}
                </div>
                </div>
            </div>
            </div>

        </div>
    </div>
    </>
    
    );
}

export default PastClients;